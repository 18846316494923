import React from 'react';
import WorkLayout from '../../components/layouts/WorkLayout';
import { graphql } from 'gatsby';
import Blockquote from '../../components/Blockquote';
import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';
import SearchEngineOptimisation from '../../components/SearchEngineOptimisation';

import Tile from '../../components/Tile';


const Work = ({data}) => {
	
	const casestudies = data.allMarkdownRemark.nodes;
	const bannerImg = data.backgroundImage;

	const backgroundImg = getImage(bannerImg);
	const bgImage = convertToBgImage(backgroundImg);

	const keywords = "Human-Centred Design, UX, Case Studies, Strategic Design, Qualitative Research, Purpose Driven";

	return (
		<WorkLayout>
			<SearchEngineOptimisation title={`Tobias Work`} kwords={keywords} />
			<main id="content" role="main">
				<div className="isolate bg-black border-b-5 border-yellow">
					<div className="container h-quarter-screen lg:h-half-screen relative overflow-hidden">
						<BackgroundImage className="w-full h-full relative bg-top bg-center bg-cover" Tag="div" {...bgImage}>						
							<div className="bg-gradient-to-r from-black w-1/2 h-full absolute top-0 left-0 z--2"></div>
							<div className="bg-gradient-to-l from-black w-1/2 h-full absolute top-0 right-0 z--2"></div>
							<div className="grid grid-cols-1 absolute top-0 w-full h-full">
								<div className="flex flex-col h-full">
									<div className="flex flex-grow w-full items-center">
										<h1 className="text-center text-white font-bold uppercase lg:text-4xl md:text-3xl sm:text-2xl text-base">We use behavioural insights and human centred design to deliver innovative &amp; valuable outcomes.</h1> 
									</div>										
								</div>
							</div>
						</BackgroundImage>
          </div>
				</div>

				<div className="isolate bg-white pt-16 pb-4">
					<div className="container m-auto lg:pt-16 lg:pb-8 md:pt-10 md:pb-5 pt-8 pb-4">
            <div className="grid grid-cols-1">
              <h2 className="lg:text-4xl md:text-3xl text-2xl uppercase mb-0 font-bold wider">Our work</h2>
            </div>
          </div>
				</div>				

				<div className="isolate bg-white pt-4 pb-4">
					<div className="container">
						<div className="grid lg:grid-cols-3 grid-cols-1 gap-8 grid-flow-row grid-flow-row-dense">
							{casestudies.map((casestudy, i) => {
								
									return (
										<Tile 
										index={i}
										calling="work" 
										key={casestudy.id} 
										image={ casestudy.frontmatter.indexPage.indexPageImage } 
										blurb={ casestudy.frontmatter.indexPage.indexPageBlurb } 
										title={ casestudy.frontmatter.indexPage.indexPageHeading } 
										tag={ casestudy.frontmatter.sector } 
										link={'/work/' + casestudy.frontmatter.slug} />
									)								
								}
							)}
						</div>
					</div>
				</div>

				<div className="isolate bg-white pt-4 pb-4">
					<div className="container">
						<div className="grid grid-cols-5 gap-4">
              <div className="col-start-2 col-span-3">
								<Blockquote 
                quote={'I do want to thank Tobias for going the extra mile with the discharge summary project. It was a valuable exercise from our end and I’ve been using the materials to demonstrate the value of the process within the CEC.'} 
                citation={'Senior Manager, Orgnasational Development & Human Factors'} 
                org={'Health NSW Clinical Excellence Commission'}
								theme={'yellow'} />
							</div>
						</div>
					</div>
				</div>

			</main>
		</WorkLayout>
	)
}

export default Work;

export const caseStudyListQuery = graphql`
query workIndexPageAssets {	
  allMarkdownRemark(
    sort: {order: ASC, fields: frontmatter___sortOrder}
    filter: {frontmatter: {templateKey: {eq: "WorkDetail"}}}
  ) {
    nodes {
      frontmatter {
        client
        sector
        slug
        indexPage {
          indexPageBlurb
          indexPageHeading
          indexPageImage 
        }
      }
      id
    }
  }
	backgroundImage: file(
    relativePath: {eq: "workshop.jpeg"}
  ) {
    childImageSharp {
      gatsbyImageData(
				width: 800, 
				placeholder: BLURRED, 
				formats: [AUTO, WEBP, AVIF]
			)
    }
  }
}
`

